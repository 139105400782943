<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-no-repeat bgi-size-800 bgi-attachment-fixed"
    style="background-image: url('media/illustrations/verification.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="#" class="mb-6">
        <h1 class="logo display-5 text-primary">Bauwise</h1>
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework text-center"
          id="kt_verify_email_form"
        >
          <div class="text-center mb-10" v-if="loading">
            <h1 class="text-dark mb-3">Please wait while we're verifying your email</h1>
          </div>
          <div v-else>
            <!--begin::Heading-->
            <div class="text-center mb-10">
              <!--begin::Title-->
              <h1
                class="text-dark mb-3"
              >{{ verifyEmailObject.isVerified ? 'Thank You' : 'Failed to verify token' }}</h1>
              <!--end::Title-->

              <!--begin::Link-->
              <div class="text-gray-400 fw-bold fs-4">{{ verifyEmailObject.msg }}</div>
              <!--end::Link-->
            </div>
            <!--begin::Heading-->

            <router-link
              type="button"
              id="kt_email_verified"
              to="/sign-in"
              class="btn btn-lg btn-primary fw-bolder"
              v-if="verifyEmailObject.isVerified"
            >
              <span class="indicator-label">Login to your account</span>
              <span class="indicator-progress">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </router-link>
          </div>
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6">
        <a href="#" class="text-muted text-hover-primary px-2">About</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "verify-email",
  props: ['token'],
  components: {
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const verifyEmailMsg = ref('Verifying Email...');
    const verifyEmailObject = reactive({
      msg: 'Verifying Email...',
      isVerified: false,
    })
    const loading = ref(true);
    const submitButton = ref<HTMLElement | null>(null);

    onBeforeMount(() => {
      setTimeout(() => {
        store.dispatch(Actions.VERIFY_EMAIL, { token: props.token }).then(() => {
          loading.value = false;
          verifyEmailObject.msg = "Email Successfully Verified! Your site is currently being created it may take from 5 - 10 mins. Thank you for your patience."
          verifyEmailObject.isVerified = true
        }).catch((error) => {
          loading.value = false;
          verifyEmailObject.msg = "Sorry, seems the verification code is incorrect, please review and try again."
          verifyEmailObject.isVerified = false
        })
      }, 5000)
    })

    return {
      // onSubmitEmailVerification,
      // verifyEmail,
      submitButton,
      verifyEmailMsg,
      verifyEmailObject,
      loading
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/sass/style";

.bgi-size-800 {
  background-size: 800px;
}
</style>