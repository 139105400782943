
import { defineComponent, ref, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "verify-email",
  props: ['token'],
  components: {
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const verifyEmailMsg = ref('Verifying Email...');
    const verifyEmailObject = reactive({
      msg: 'Verifying Email...',
      isVerified: false,
    })
    const loading = ref(true);
    const submitButton = ref<HTMLElement | null>(null);

    onBeforeMount(() => {
      setTimeout(() => {
        store.dispatch(Actions.VERIFY_EMAIL, { token: props.token }).then(() => {
          loading.value = false;
          verifyEmailObject.msg = "Email Successfully Verified! Your site is currently being created it may take from 5 - 10 mins. Thank you for your patience."
          verifyEmailObject.isVerified = true
        }).catch((error) => {
          loading.value = false;
          verifyEmailObject.msg = "Sorry, seems the verification code is incorrect, please review and try again."
          verifyEmailObject.isVerified = false
        })
      }, 5000)
    })

    return {
      // onSubmitEmailVerification,
      // verifyEmail,
      submitButton,
      verifyEmailMsg,
      verifyEmailObject,
      loading
    };
  },
});
